import { api, client } from 'src/api'
import type { SignUpParams } from 'src/components/SignUpForm/getSignUpParams'
import type { ClassFormat, ObeClassType } from 'src/types/ObeClass'
import type {
  Account,
  ConditionalDisplayAttributes,
  FeatureAnnouncements,
  NotificationSettings,
  UserType,
} from 'src/types/User'
import { getGeoLocationFromHeaders } from 'src/utils/getGeoLocationFromHeaders'
import { shouldNotLogUserOutOnNoNetwork } from 'src/utils/network'

export const acceptUpdatedTos = async (userId: string, tosVersion: number) => {
  return await client.put<void>(
    `/api/v3/users/${userId}/accept_updated_tos?tos_version=${tosVersion}`,
  )
}

export const changePassword = async (
  userId: string,
  currentPassword: string,
  newPassword: string,
) =>
  await client.patch<void>(`/api/v3/users/${userId}/change_password`, {
    user: {
      current_password: currentPassword,
      password: newPassword,
    },
  })

export const createUser = async (params: SignUpParams) => {
  const response = await client.post<{ id: string }>('/users.json', params)

  api.sessionState = {
    authorization: response.headers.authorization,
    userId: response.data.id,
  }

  return response
}

export const getClassHistory = async (
  userId: string,
  startTime: Date | undefined,
  endTime: Date | undefined,
  page: number,
  perPage = 30,
) =>
  await client.get<{
    completedClasses: {
      classDto: ObeClassType[]
      id: string
      created: string
      type: ClassFormat
      viewStartTime: string
    }
    completedCount: number
  }>(`/api/v3/users/${userId}/class_history`, {
    params: {
      start_time: startTime,
      end_time: endTime,
      per_page: perPage,
      page,
    },
  })

export const getCurrentUser = async () => {
  if (api.sessionState) {
    try {
      const { data, headers } = await client.get<UserType | null>('/api/v3/users/me')

      if (data) {
        obe.analytics.identify(data.id, { email: data.email })

        // add userId back into existing sessions
        // can be removed after 2+ weeks have passed
        api.sessionState = {
          ...api.sessionState,
          userId: data.id,
        }

        return {
          currentUser: data,
          geoLocation: getGeoLocationFromHeaders(headers),
        }
      }
    } catch (error) {
      const windowLocationParts = window.location.pathname.split('/')

      if (shouldNotLogUserOutOnNoNetwork({ windowLocationParts })) {
        if (navigator.onLine) {
          api.clearSessionState()
          window.location.href = '/login'
        }
      } else {
        api.clearSessionState()
        window.location.href = '/login'
      }
    }
  }

  return {
    currentUser: null,
    geoLocation: null,
  }
}

export const getMostTakenInstructor = (userId: string) =>
  client.get<string>(`/api/v3/users/${userId}/most_taken_instructor`)

export const getStreak = async (userId: string) => {
  const response = await client.get<number>(`/api/v3/users/${userId}/streak`)
  return response.data
}

export const requestPasswordReset = async (email: string) => {
  await client.post<void>('/api/passwords.json', { user: { email }, api_version: 'v3' })
}

export const resetPassword = async (password: string, reset_password_token: string) =>
  await client.put<void>('/api/passwords.json', {
    api_version: 'v3',
    user: {
      password,
      reset_password_token,
    },
  })

export const updateConditionalDisplay = async (
  userId: string,
  conditionalAttributeValues: ConditionalDisplayAttributes,
) => {
  return client.put<{ account: Partial<Account> }>(
    `/api/v3/users/${userId}/update_conditional_display_attributes`,
    {
      user: {
        conditional_display_attributes: conditionalAttributeValues,
      },
    },
  )
}

export const updateFeatureAnnouncements = async (
  userId: string,
  featureAnnouncements: FeatureAnnouncements,
) => {
  return client.put<{ account: Partial<Account> }>(
    `/api/v3/users/${userId}/update_feature_announcements`,
    {
      user: {
        feature_announcements: featureAnnouncements,
      },
    },
  )
}

export const updateNotificationSettings = async (
  userId: string,
  notificationSettingValues: NotificationSettings,
) => {
  return client.put<{ account: Partial<Account> }>(
    `/api/v3/users/${userId}/update_notification_settings`,
    {
      user: {
        notificationSettings: notificationSettingValues,
      },
    },
  )
}

export const getEmailAvailability = (email: string) =>
  client.post('/api/v4/users/email_availability', { email })
